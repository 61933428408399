import React from 'react'

import { addStoreLocaleByCookie } from 'lib/storeLocale/client'

import ArtworksIcon from 'svg/your-artworks.svg'
import AddressIcon from 'svg/address.svg'
import CuratorNotesIcon from 'svg/curator-notes.svg'
import ProfileIcon from 'svg/profile.svg'
import SettingsIcon from 'svg/settings.svg'
import SalesIcon from 'svg/sales.svg'
import OffersIcon from 'svg/offers.svg'
import PromotionsIcon from 'svg/rocket.svg'
import AppConnector, { type ConnectorProps } from 'apps/private/studio/artworks/connectors/App'
import { SECTIONS, SUB_SECTIONS } from 'apps/private/studio/artworks//helpers/constants'
import { updateStudioUrl } from 'apps/private/studio/artworks//helpers/url'
import { updateStudioSalesUrl } from 'apps/private/studio/sales//helpers/url'
import { NavigationIcons } from './styles'
const { DASHBOARD } = SECTIONS
const { ACCOUNT, ADDRESS, ARTWORKS, CURATOR_NOTES, PROFILE_INFORMATION, PROMOTIONS } = SUB_SECTIONS

type Props = ConnectorProps & { type: 'mobile' | 'desktop' }

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const { section, type } = props
  return (
    <NavigationIcons data-section='studio-navigation' data-type={type}>
      <button
        type='button'
        data-type='nav-link'
        onClick={(ev: React.SyntheticEvent<HTMLElement>) => {
          updateStudioUrl(`/studio/${ARTWORKS}`, ARTWORKS)
        }}
        {...([DASHBOARD, ARTWORKS].includes(section)
          ? {
              'data-active': 'active',
            }
          : {})}
      >
        <ArtworksIcon />
        <span>Manage Artworks</span>
      </button>

      <button
        type='button'
        data-type='nav-link'
        onClick={(ev: React.SyntheticEvent<HTMLElement>) => {
          updateStudioSalesUrl({section: 'sales'})
        }}
        {...(section === 'sales'
          ? {
              'data-active': 'active',
            }
          : {})}
      >
        <SalesIcon />
        <span>Sales Dashboard</span>
      </button>

      {/* Using these ( <a /> ) while pages get migrated to Studio */}
      <a data-type='nav-link' href='/accounts/offers' target='_blank' rel='nofollow' type='button'>
        <OffersIcon />
        <span>Offers Dashboard</span>
      </a>

      {type === 'desktop' && (
        <>
          <button
            type='button'
            data-type='nav-link'
            onClick={(ev: React.SyntheticEvent<HTMLElement>) => {
              updateStudioUrl(`/studio/${ADDRESS}`, ADDRESS)
            }}
            {...(section === ADDRESS
              ? {
                  'data-active': 'active',
                }
              : {})}
          >
            <AddressIcon />
            <span>Addresses</span>
          </button>

          <button
            type='button'
            data-type='nav-link'
            onClick={(ev: React.SyntheticEvent<HTMLElement>) => {
              updateStudioUrl(`/studio/${CURATOR_NOTES}`, CURATOR_NOTES)
            }}
            {...(section === CURATOR_NOTES
              ? {
                  'data-active': 'active',
                }
              : {})}
          >
            <CuratorNotesIcon data-icon='curator-notes' />
            <span>Curator Notes</span>
          </button>
          <button
            type='button'
            data-type='nav-link'
            onClick={(ev: React.SyntheticEvent<HTMLElement>) => {
              updateStudioUrl(`/studio/${ACCOUNT}`, ACCOUNT)
            }}
            {...(section === ACCOUNT
              ? {
                  'data-active': 'active',
                }
              : {})}
          >
            <SettingsIcon data-icon='profile' />
            <span>Account</span>
          </button>

          <button
            type='button'
            data-type='nav-link'
            onClick={(ev: React.SyntheticEvent<HTMLElement>) => {
              updateStudioUrl(`/studio/${PROFILE_INFORMATION}`, PROFILE_INFORMATION)
            }}
            {...(section === PROFILE_INFORMATION
              ? {
                  'data-active': 'active',
                }
              : {})}
          >
            <ProfileIcon data-icon='profile' />
            <span>Profile Information</span>
          </button>

          <button
            type='button'
            data-type='nav-link'
            onClick={(ev: SyntheticEvent<HTMLElement>) => {
              ev.preventDefault()
              const location = addStoreLocaleByCookie(`/studio/${PROMOTIONS}`)
              window.location = location
            }}
            {...(section === PROMOTIONS ? { 'data-active': 'active' } : {})}
          >
            <PromotionsIcon data-icon='profile' />
            <span>Promotions</span>
          </button>
        </>
      )}
    </NavigationIcons>
  )
}

export default AppConnector(Component)
