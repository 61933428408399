import React, { FC } from 'react'
import SALink from 'components/SALink'
import SessionConnect, { ConnectorProps as SessionProps } from 'containers/Session'
import StudioIcon from 'svg/studio-icon.svg'
import styles from './StudioLink.module.scss'

const StudioLink: FC<SessionProps> = (props) => {
  const { isSessionReady, authenticated, userTypeId } = props

  // Must have session | authenticated | artist
  if (!isSessionReady || !authenticated || userTypeId !== 2) {
    return null
  }

  return (
    <div className={styles.mobileHiddenIconContainer}>
      <SALink
        data-gtm-event='top-nav'
        data-type='studio'
        href='/studio'
        title='View Studio'
        rel='nofollow'
      >
        <StudioIcon className={styles.studioIcon} data-type='studio-icon' />
      </SALink>
    </div>
  )
}

export default SessionConnect(StudioLink)
