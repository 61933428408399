import React, { FC } from 'react'
import UserPreferences, { ConnectorProps as UserPreferencesProps } from 'connectors/UserPreferences'
import User from './User/User'
import Cart from './Cart/Cart'
import styles from './IconActions.module.scss'
import SearchIcon from 'svg/search-icon.svg'
import FavoritesIcon from './Favorites/FavoritesIcon'
import HamburgerMenuIcon from 'svg/hamburger.svg'
import CloseIcon from 'svg/close-icon.svg'
import CountryFlagIcon from './CountryFlag/CountryFlagIcon'
import { useHeaderContext } from '../HeaderContext'
import StudioLink from './Studio/StudioLink'

type IconActionProps = UserPreferencesProps & { modalName: string }

const IconActions: FC<IconActionProps> = ({ modalName, userPreferencesCountryCode }) => {
  const { setSelectedCategory, setMobileHeaderConfig, mobileHeaderConfig } = useHeaderContext()

  const handleToggleHamburgerMenu = () => {
    setMobileHeaderConfig((previousMobileHeaderConfig) => ({
      ...previousMobileHeaderConfig,
      isMobileMenuOpen: !previousMobileHeaderConfig.isMobileMenuOpen,
      mobileMenuContent: 'categories',
    }))
    setSelectedCategory(null)
  }

  const handleSearchClick = () => {
    setMobileHeaderConfig((previousMobileHeaderConfig) => ({
      ...previousMobileHeaderConfig,
      isMobileMenuOpen: true,
      mobileMenuContent: 'search',
      mobileTopHeader: 'search',
    }))
  }

  // TODO - Try Skeleton Loader
  // only to apply loading at the same time as the other icons
  // if (!userPreferencesCountryCode) {
  //   return null
  // }

  return (
    <div className={styles.icons}>
      <div onClick={handleSearchClick} className={styles.searchIconContainer}>
        <SearchIcon />
      </div>

      <StudioLink />

      <div className={styles.mobileHiddenIconContainer}>
        <CountryFlagIcon modalName={modalName} />
      </div>

      <FavoritesIcon />

      <User />

      <Cart />

      <div className={styles.hamburgerBox} onClick={handleToggleHamburgerMenu}>
        {mobileHeaderConfig.isMobileMenuOpen ? (
          <CloseIcon className={styles.closeHamburgerIcon} />
        ) : (
          <HamburgerMenuIcon className={styles.hamburguerIcon} />
        )}
      </div>
    </div>
  )
}

export default UserPreferences(IconActions)
