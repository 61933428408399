import React from 'react'
import SearchInput from './SearchInput'
import CloseIcon from 'svg/close.svg'
import styles from './SearchInput.module.scss'
import classNames from 'classnames'
import { submitSearch } from '../helpers'
import { useHeaderContext } from '../HeaderContext'

const SearchTopHeader = () => {
  const { mobileHeaderConfig, setMobileHeaderConfig } = useHeaderContext()

  const handleClose = () => {
    setMobileHeaderConfig((previousMobileHeaderConfig) => ({
      ...previousMobileHeaderConfig,
      mobileTopHeader: 'mainTopHeader',
      mobileMenuContent: 'null',
      isMobileMenuOpen: false,
    }))
  }

  return (
    <div
      className={classNames(styles.searchHeaderContainer, {
        [styles.searchHeaderContainerOpened]: mobileHeaderConfig.isMobileMenuOpen,
      })}
    >
      <SearchInput onSearch={submitSearch} />
      <div className={styles.searchHeaderCloseIconBox} onClick={handleClose}>
        <CloseIcon />
      </div>
    </div>
  )
}

export default SearchTopHeader
