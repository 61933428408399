import React, { FC, useMemo, useState } from 'react'
import MobileHeader from './MobileHeader/MobileHeader'
import DesktopHeader from './DesktopHeader/DesktopHeader'
import { HeaderProvider, MobileHeaderConfig } from './HeaderContext'
import ABTestConnector, { ConnectorProps as ABTestProps } from 'connectors/ABTest'
import { NavigationLinks } from './NavigationLinks'
import { abTestInSubNavLinks, transformNavLinks } from './helpers'
import CartUrgencyBar from 'components/CartUrgencyBar'
import styles from './Header.module.scss'

const Header: FC<ABTestProps> = ({ abTest }) => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null)
  const [mobileHeaderConfig, setMobileHeaderConfig] = useState<MobileHeaderConfig>({
    mobileTopHeader: 'mainTopHeader',
    mobileMenuContent: 'null',
    isMobileMenuOpen: false,
  })

  const transformedNavigationLinks = useMemo(() => {
    return transformNavLinks({
      navigationLinks: NavigationLinks,
      transformFunction: (navigationLinksParam) =>
        abTestInSubNavLinks({ navigationLinks: navigationLinksParam, abTest }),
    })
  }, [abTest])

  return (
    <HeaderProvider
      value={{
        selectedCategory,
        setSelectedCategory,
        mobileHeaderConfig,
        setMobileHeaderConfig,
        navigationLinks: transformedNavigationLinks,
      }}
    >
      <MobileHeader />
      <DesktopHeader />
      <div className={styles.cartUrgencyBox}>
        <CartUrgencyBar />
      </div>
    </HeaderProvider>
  )
}

export default ABTestConnector(Header)
