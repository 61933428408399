import React from 'react'
import SALink from 'components/SALink'
import StudioIcon from 'svg/studio-logo.svg'
import Logos from './Logos'
import { Header } from './styles'
import Authentication from './Authentication'
import StudioNavigation from './StudioNavigation'
import MobileNavLinks from './MobileNavLinks'

/*
There are design issues with the nav items, so trying to limit the complexity of rendering components multiple times


Mobile/Desktop
--
Back ( Desktop )

Hamburger ( Mobile )

Studio Logo ( Both )

Nav Links ( Both )

Authentication ( Both )
*/
const Navigation = ({
  siteContext,
}: {
  siteContext: string
}): React.ReactElement<React.ComponentProps<any>, any> => {
  return (
    <>
      <Header data-section='header'>
        <div data-section='top'>
          <Logos siteContext={siteContext} />
        </div>

        <div data-section='bottom'>
          <MobileNavLinks />
          <SALink href='/studio' data-type='logo'>
            <StudioIcon />
            <h2>Studio</h2>
          </SALink>

          <StudioNavigation type='desktop' />

          <span data-type='spacer' />
        </div>

        <Authentication />
      </Header>

      <StudioNavigation type='mobile' />
    </>
  )
}

export default Navigation
