import React from 'react'
import NProgress from 'nprogress'
import Router from 'next/router'
import FlashMessage from 'components/FlashMessage'
import WindowListener from 'components/Screen'
import useClientSession from 'hooks/clientSession'
import TakeoverIndicator from './TakeoverIndicator'
import HeaderComponent from './Main/Header'
// https://ricostacruz.com/nprogress/ slim JS loading bar
// We are connecting it to page ( clientside ) transitions in NEXTjs
// to give user some indication of an ACTION taking place
NProgress.configure({
  easing: 'ease',
  speed: 500,
  showSpinner: true,
  trickleSpeed: 300,
})

Router.onRouteChangeStart = (url) => NProgress.start()

Router.onRouteChangeComplete = () => NProgress.done()

Router.onRouteChangeError = () => NProgress.done()

const Header = () => {
  useClientSession()

  return (
    <nav data-section='main-navigation'>
      <TakeoverIndicator />

      <HeaderComponent />

      <FlashMessage />

      <WindowListener />
    </nav>
  )
}
export default Header
