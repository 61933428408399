import React, { FC } from 'react'
import styles from './Footer.module.scss'
import { COPYRIGHTS_LINKS, TOP_CATEGORIES } from './FooterData'
import PreferencesFooter from './Preferences/Preferences'
import SALink from 'components/SALink'
import classNames from 'classnames'
import FooterLinks from './Links/Links'
import MobileFooterLinks from './Links/MobileLinks'
import MailSection from './MailSection/MailSection'

type FooterProps = {
  isStudio?: boolean
}

const Footer: FC<FooterProps> = ({ isStudio }) => {
  return (
    <footer data-section='footer' className={styles.footerComponent}>
      {!isStudio && (
        <>
          <div className={styles.topCategoriesWrapper}>
            {TOP_CATEGORIES.map(({ title, categories }) => (
              <div className={styles.topCategoriesContainer} key={title}>
                <span>{title}</span>
                <div className={styles.topCategoriesLinks} data-type='top-categories-links'>
                  {categories.map(({ title: categoryTitle, url }) => (
                    <SALink key={categoryTitle} href={url} data-gtm-event='footer'>
                      {categoryTitle}
                    </SALink>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <hr className='hrDivider' />
        </>
      )}

      <div className={styles.footerContainer}>
        <div className={styles.topSectionWrapper}>
          <div className={styles.topSectionContainer}>
            <MailSection isStudio={isStudio} />
            <div className={styles.topSectionColumnSeparator} />
            <FooterLinks isStudio={isStudio} />
          </div>

          <hr className='hrDivider' />
        </div>

        <div className={styles.mobileFooterLinks}>
          <MobileFooterLinks isStudio={isStudio} />
        </div>

        <div className={styles.copyrightWrapper}>
          <div className={styles.copyrightBox}>
            <div className={styles.copyrightItemsContainer}>
              <div className={styles.copyrightLinksBox} data-type='copyright-link-box'>
                {COPYRIGHTS_LINKS.map(({ title, link }) => {
                  const isUserPreferences = link === '#userPreferences'
                  // Opens OneTrust modal
                  return isUserPreferences ? (
                    <span
                      key={title}
                      className={classNames('defaultTypographyTiny', 'ot-sdk-show-settings')}
                    >
                      {title}
                    </span>
                  ) : (
                    <SALink
                      key={title}
                      href={link}
                      data-gtm-event='footer'
                      className={classNames('defaultTypographyTiny')}
                    >
                      {title}
                    </SALink>
                  )
                })}
              </div>
            </div>
          </div>
          <PreferencesFooter />

          <div className={styles.copyrightRecaptcha}>
            <span className='defaultTypographyTiny'>
              © {new Date().getFullYear()} Saatchi Art. All Rights Reserved.
            </span>
            <p className='defaultTypographyTiny'>
              {`This site is protected by reCAPTCHA and the Google `}
              <SALink
                className={styles.copyrightRecaptchaLink}
                target='_blank'
                data-gtm-event='footer'
                rel='noopener noreferrer'
                href='https://policies.google.com/privacy'
                title='Privacy Policy'
              >
                Privacy Policy
              </SALink>
              {` and `}
              <SALink
                className={styles.copyrightRecaptchaLink}
                target='_blank'
                data-gtm-event='footer'
                rel='noopener noreferrer'
                href='https://policies.google.com/terms'
                title='Terms of Service'
              >
                Terms of Service
              </SALink>
              {` apply.`}
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
