import React, { FC } from 'react'
import styles from './Cart.module.scss'
import CartIcon from 'svg/bag.svg'
import SALink from 'components/SALink'
import CartConnect, { ConnectorProps as CartProps } from 'connectors/Cart'
import { resetNotification } from 'components/CartUrgencyBar/helper'

type CartComponentProps = CartProps

const Cart: FC<CartComponentProps> = ({ cartCount }) => {
  return (
    <SALink
      aria-label='View your cart'
      data-type='cart-icon'
      data-gtm-event='top-nav'
      href='/cart'
      rel='nofollow'
      title='View Cart'
      onClick={resetNotification}
    >
      <div className={styles.cart}>
        <CartIcon className={styles.cartIcon} />
        <span className={styles.cartCount} data-type='cart-count'>
          {cartCount || 0}
        </span>
      </div>
    </SALink>
  )
}

export default CartConnect(Cart)
