import React, { useMemo } from 'react'
import AccountTopHeader from './AccountTopHeader'
import MainTopHeader from './MainTopHeader'
import UserTopHeader from './UserTopHeader'
import SearchTopHeader from '../../Search/SearchTopHeader'
import { useHeaderContext } from '../../HeaderContext'

const HEADER_CONTENT = {
  ['mainTopHeader']: MainTopHeader,
  ['user']: UserTopHeader,
  ['account']: AccountTopHeader,
  ['search']: SearchTopHeader,
}

const TopHeader = () => {
  const { mobileHeaderConfig } = useHeaderContext()

  const ContentComponent = useMemo(() => HEADER_CONTENT[mobileHeaderConfig.mobileTopHeader], [
    mobileHeaderConfig.mobileTopHeader,
  ])

  return ContentComponent ? <ContentComponent /> : null
}

export default TopHeader
