import React, { useEffect, useState } from 'react'

import { addStoreLocaleByCookie } from 'lib/storeLocale/client'
import SALink from 'components/SALink'
import { lockScroll, unlockScroll } from 'lib/lockScroll'

const MobileNavLinks = (): React.ReactElement<React.ComponentProps<any>, any> => {
  const [isMobileOpen, updateIsMobileOpen] = useState(false) // Should the Mobile Menu Show

  useEffect(() => {
    isMobileOpen ? lockScroll() : unlockScroll()
  }, [isMobileOpen])
  return (
    <>
      <label
        htmlFor='toggle-menu'
        data-type='hamburger'
        {...(isMobileOpen
          ? {
              'data-mobile-open': true,
            }
          : {})}
      >
        <input
          id='toggle-menu'
          type='checkbox'
          {...(isMobileOpen
            ? {
                checked: 'true',
              }
            : {})}
          /* Issue with history NOT resetting state */
          {...(isMobileOpen
            ? {
                'data-checked': 'true',
              }
            : {})}
          onChange={(ev) => {
            const { checked } = ev.currentTarget
            updateIsMobileOpen(checked || false)
          }}
        />
        <span data-type='line' />
        <span data-type='line' />
        <span data-type='line' />
      </label>

      <div
        data-section='mobile-nav'
        {...(isMobileOpen
          ? {
              'data-mobile-open': true,
            }
          : {})}
      >
        <div data-type='mobile-nav-content'>
          <SALink data-gtm-event='nav header' data-type='account-links' href='/' rel='nofollow'>
            Go to Saatchi Art
          </SALink>

          <span data-type='line' />

          <SALink
            data-gtm-event='nav header'
            data-type='account-links'
            href='/studio/artworks'
            rel='nofollow'
          >
            Manage Artworks
          </SALink>

          <SALink
            data-gtm-event='nav header'
            data-type='account-links'
            href='/studio/sales'
            rel='nofollow'
          >
            Sales Dashboard
          </SALink>

          <SALink
            data-gtm-event='nav header'
            data-type='account-links'
            href='/accounts/offers'
            rel='nofollow'
          >
            Offers Dashboard
          </SALink>

          <SALink
            data-gtm-event='nav header'
            data-type='account-links'
            href='/studio/address'
            rel='nofollow'
          >
            Address Book
          </SALink>
          <SALink
            data-gtm-event='nav header'
            data-type='account-links'
            href='/studio/account'
            rel='nofollow'
          >
            Artist Account
          </SALink>

          <SALink
            data-gtm-event='nav header'
            data-type='account-links'
            href='/studio/profile-information'
            rel='nofollow'
          >
            Profile Information
          </SALink>

          <SALink
            data-gtm-event='nav header'
            data-type='account-links'
            href='/studio/curator-notes'
            rel='nofollow'
          >
            Curator Notes
          </SALink>

          <SALink
            data-gtm-event='nav header'
            data-type='account-links'
            href='/studio/promotions'
            rel='nofollow'
            onClick={(ev: SyntheticEvent<HTMLElement>) => {
              ev.preventDefault()
              const location = addStoreLocaleByCookie(`/studio/promotions`)
              window.location = location
            }}
          >
            Paid Promotions
          </SALink>

          <SALink
            data-gtm-event='nav header'
            data-type='account-links'
            href='https://support.saatchiart.com/hc/en-us'
            rel='nofollow'
          >
            Support
          </SALink>
        </div>
      </div>
    </>
  )
}

export default MobileNavLinks
