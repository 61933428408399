import React from 'react'
import styles from './CategoriesCTA.module.scss'
import classNames from 'classnames'
import { useHeaderContext } from '../../HeaderContext'
import SALink from 'components/SALink'
import { FEATURED_CATEGORIES_MAPPING } from '../../NavigationLinks'

interface CategoriesHoverContainerProps {
  containerRef: React.RefObject<HTMLDivElement>
}

const CategoriesHoverContainer = ({ containerRef }: CategoriesHoverContainerProps) => {
  const { selectedCategory, navigationLinks } = useHeaderContext()

  const navigationCategory = navigationLinks.find(({ title }) => title === selectedCategory)
  const hasSubcategories = navigationCategory && navigationCategory?.links?.length > 0

  return hasSubcategories ? (
    <div
      ref={containerRef}
      className={classNames(styles.categoriesHoverContainer, {
        [styles.categoriesHoverContainerHover]: !!selectedCategory,
      })}
    >
      <div className={styles.categoriesHoverOptions}>
        {navigationLinks.map(({ title: categoryTitle, links }) =>
          links.map(({ title, links: subcategoryLinks }, index) => (
            <div
              className={classNames(styles.categoriesHoverOptionsSubcategories, {
                [styles.categoriesHoverOptionsSubcategoriesSelected]:
                  selectedCategory === categoryTitle,
              })}
              key={`${title}${index.toString()}`}
            >
              <span className='defaultTypographyH6'>{title}</span>
              {subcategoryLinks.map(({ title: optionTitle, url: optionUrl }, index) => (
                <SALink
                  key={`${optionTitle}${index}`}
                  className={classNames('defaultTypographyXSmall', 'defaultLink')}
                  href={optionUrl}
                  data-gtm-event='top-nav'
                >
                  {optionTitle}
                </SALink>
              ))}
            </div>
          ))
        )}
      </div>
      <div className={styles.featureSection}>
        {navigationLinks.map(({ title: categoryTitleImage, feature }) =>
          feature.map(({ title, linkTo, url }, index) => (
            <SALink
              className={classNames('defaultLink', styles.categoriesHoverImageContainer, {
                [styles.categoriesHoverImageContainerSelected]:
                  selectedCategory === categoryTitleImage,
              })}
              href={linkTo}
              data-gtm-event='top-nav'
              key={`${categoryTitleImage}-${index.toString()}`}
            >
              <img src={url} />
              <div>
                <p
                  className={classNames(
                    'defaultTypographyCtaMedium',
                    styles.categoriesHoverImageText
                  )}
                >
                  {title}
                </p>
              </div>
            </SALink>
          ))
        )}
      </div>
    </div>
  ) : null
}

export default CategoriesHoverContainer
