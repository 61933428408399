import React, { FC, useMemo } from 'react'
import { FOOTER_LINKS, LinkObject, STUDIO_FOOTER_LINKS } from '../FooterData'
import styles from './Links.module.scss'
import Accordion from 'components/SA/Accordion'
import LinkItem from './LinkItem'

type MobileFooterLinksProps = {
  isStudio?: boolean
}

type LinksContainerProps = {
  links: ReadonlyArray<LinkObject>
}

const LinksContainer: FC<LinksContainerProps> = ({ links }) => {
  return (
    <div className={styles.linksBox}>
      {links.map(({ title: linkTitle, url, icon }) => (
        <LinkItem key={linkTitle} title={linkTitle} url={url} icon={icon} />
      ))}
    </div>
  )
}

const MobileFooterLinks: FC<MobileFooterLinksProps> = ({ isStudio }) => {
  const footerItems = useMemo(() => {
    const links = isStudio ? STUDIO_FOOTER_LINKS : FOOTER_LINKS

    return links.map(({ title, links }) => ({
      title,
      content: <LinksContainer links={links} />,
    }))
  }, [isStudio])

  return (
    <div className={styles.mobileLinksContainer}>
      <Accordion items={footerItems} headerCustomStyle={styles.customAccordionContainer} />
    </div>
  )
}

export default MobileFooterLinks
