import React, { FC, PropsWithChildren } from 'react'
import styles from './Accordion.module.scss'
import classNames from 'classnames'
import PlusIcon from 'svg/heavier-plus.svg'
import { AccordionItemType } from './Accordion'

type AccordionItemProps = Pick<
  AccordionItemType,
  'title' | 'headerCustomStyle' | 'contentCustomStyle' | 'itemCustomStyle'
> & {
  activeIndex: ReadonlyArray<string>
  onChangeIndex: (index: string) => void
}

const AccordionItem: FC<PropsWithChildren<AccordionItemProps>> = ({
  children,
  title,
  activeIndex,
  onChangeIndex,
  headerCustomStyle = '',
  contentCustomStyle = '',
  itemCustomStyle = '',
}) => {
  const isTitleActiveIndex = activeIndex.includes(title)

  return (
    <div className={styles.accordionItemWrapper}>
      <input
        type='checkbox'
        id={`toggle-${title}`}
        className={styles.toggleInput}
        checked={isTitleActiveIndex}
        readOnly
      />
      <label htmlFor={`toggle-${title}`}>
        <div
          className={classNames({
            [itemCustomStyle]: itemCustomStyle !== '',
          })}
        >
          <div
            className={classNames(styles.itemBox, {
              [styles.itemBoxVisible]: isTitleActiveIndex,
              [headerCustomStyle]: headerCustomStyle !== '',
            })}
            onClick={() => onChangeIndex(title)}
          >
            <span className={styles.itemBoxSpan}>{title}</span>
            <PlusIcon className={styles.expandCollapseIcon} />
          </div>
          <div
            className={classNames(styles.contentBox, {
              [styles.contentBoxVisible]: isTitleActiveIndex,
              [contentCustomStyle]: contentCustomStyle !== '' && isTitleActiveIndex,
            })}
          >
            {children}
          </div>
          <hr className={classNames('hrDivider', styles.accordionItemDivider)} />
        </div>
      </label>
    </div>
  )
}

export default AccordionItem
