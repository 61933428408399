import { FlashMessageType } from 'types/Shared/FlashMessage'

export type Props = {
  available: boolean
  clearMessage: () => void
  closeFlash: (ev?: any) => void
  isVisible?: boolean
  messages: Array<string>
  type: FlashMessageType
}

export const DEFAULT_STYLES = {
  position: 'fixed',
  top: '0',
  width: '100vw',
  minHeight: '50px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  willChange: 'transform, opacity',
  zIndex: '1005',
}

export const ANIMATION = {
  from: {
    opacity: 0,
    transform: 'translate3d(0,-50%,0)',
  },
  enter: {
    opacity: 1,
    transform: 'translate3d(0,0,0)',
  },
  leave: {
    opacity: 0,
    transform: 'translate3d(0,-50%,0)',
  },
}
