import React, { useEffect, useRef, useState } from 'react'
import styles from './MobileHeader.module.scss'
import TopHeader from './MainTopHeader/TopHeader'
import MenuContent from './MenuContent/MenuContent'
import classNames from 'classnames'
import { useHeaderContext } from '../HeaderContext'

const HEADERS_WITH_BORDER = ['mainTopHeader', 'search']

const MobileHeader = () => {
  const { mobileHeaderConfig } = useHeaderContext()
  const stickyRef = useRef<HTMLDivElement | null>(null)
  const [topValue, setTopValue] = useState(0)

  const updateTopValue = () => {
    if (stickyRef.current) {
      const rect = stickyRef.current.getBoundingClientRect()
      const bottomPosition = rect.bottom
      setTopValue(bottomPosition)
    }
  }

  useEffect(() => {
    updateTopValue()

    window.addEventListener('scroll', updateTopValue)
    window.addEventListener('resize', updateTopValue)

    return () => {
      window.removeEventListener('scroll', updateTopValue)
      window.removeEventListener('resize', updateTopValue)
    }
  }, [])

  useEffect(() => {
    const isScrollDisabled =
      mobileHeaderConfig.isMobileMenuOpen && mobileHeaderConfig.mobileTopHeader !== 'search'
    document.body.style.overflow = isScrollDisabled ? 'hidden' : 'auto'

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [mobileHeaderConfig])

  return (
    <div className={styles.mobileHeaderContainer} data-type='mobile-header'>
      <div
        className={classNames(styles.mobileHeaderSticky, {
          [styles.mobileHeaderStickyBorder]: HEADERS_WITH_BORDER.includes(
            mobileHeaderConfig.mobileTopHeader
          ),
        })}
        ref={stickyRef}
      >
        <TopHeader />
      </div>
      <div
        className={styles.mobileHeaderMenuContent}
        style={{ '--dynamic-top-menu': `${topValue - 1}px` } as React.CSSProperties} // - 1 necessary to adjust pixels in mobile
      >
        <MenuContent />
      </div>
    </div>
  )
}

export default MobileHeader
