import React from 'react'
import SALink from 'components/SALink'
import SaatchiLogo from 'svg/saatchi-logo.svg'
import ToafLogo from 'svg/toaf-logo.svg'

const TOAF = 'toaf'

const Logos = (props: { siteContext?: string }) => {
  const { siteContext } = props
  console.log({ siteContext })
  return (
    <div data-section='logos'>
      <SALink
        aria-label='Saatchi Art logo'
        data-active={siteContext !== TOAF ? null : 'true'}
        data-gtm-event='nav header'
        data-type='logo'
        href={process.env.SITE_URL || '/'}
      >
        <SaatchiLogo alt='Saatchi Art logo' width='122' height='18' />
      </SALink>

      <SALink
        aria-label='The Other Art Fair'
        data-active={siteContext === TOAF ? 'true' : null}
        data-gtm-event='nav header'
        data-type='logo'
        href={process.env.TOAF_SITE_URL || '/theotherartfair'}
      >
        <ToafLogo alt='The Other Art Fair logo' width='151' height='18' />
      </SALink>
    </div>
  )
}

export default Logos
