import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { getIsClientReady } from 'selectors/shared/client'

const mapStateToProps = createStructuredSelector({
  isClientReady: getIsClientReady,
})

export type ConnectorProps = {
  isClientReady: boolean
}

export default connect(mapStateToProps)
