import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getLocaleCountryCode } from 'selectors/shared/locale'
import { getUserPreferencesCountryCode } from 'selectors/user/cookie'
import {
  getFullName,
  getIsLimitedArtist,
  getIsTakeover,
  getMeasurementUnit,
  getProfileUrl,
  getSession,
  getUserID,
  getUserInitials,
  getUserTypeId,
  isAuthenticated,
  isSessionSet,
} from 'selectors/user/session'
import { getLocation } from 'selectors/shared/location'

const mapStateToProps = createStructuredSelector({
  authenticated: isAuthenticated,
  userInitials: getUserInitials,
  fullName: getFullName,
  isTakeover: getIsTakeover,
  isLimitedArtist: getIsLimitedArtist,
  localeCountryCode: getLocaleCountryCode,
  location: getLocation,
  measurementUnit: getMeasurementUnit,
  profileUrl: getProfileUrl,
  selectedCountryCode: getUserPreferencesCountryCode,
  session: getSession,
  isSessionReady: isSessionSet,
  userId: getUserID,
  userTypeId: getUserTypeId,
})

export type ConnectorProps = {
  authenticated: boolean
  userInitials: string
  fullName: string
  isLimitedArtist: boolean
  isTakeover: boolean
  localeCountryCode: string
  location: string
  measurementUnit: string
  profileUrl: string
  selectedCountryCode: string
  session: Record<string, any>
  isSessionReady: boolean
  userId: number
  userTypeId: number
}

export default connect(mapStateToProps, null)
