import { addStoreLocaleByCookie } from 'lib/storeLocale/client'
import { updateAppUrl } from 'lib/url/route'
import { stringToQueryObject, objectToQueryString } from 'lib/helpers'
import { NavigationLinksArray } from './types'

const BROWSE = `/public/browse`
const CURATION = `/ops/curation`
const VISII_SEARCH = '/public/visualSearch'

// const COLLECTIONS = `/saatchi/browse2`
// const USER_ARTWORKS = `/saatchi/browse2`
const defaultSearch = (query: string) => {
  const location = addStoreLocaleByCookie(`/all?query=${encodeURIComponent(query)}`)
  window.location.href = location
}

// Custom Search Logic
const CUSTOM_SEARCH = [
  {
    page: BROWSE,
    delayed: true,
    action: (query: string): void => {
      const { pathname, search } = window.location
      // We NEED to break down search and merge/overwrite QUERY
      const queryObject = stringToQueryObject(search)
      const href = `${pathname}?${objectToQueryString({
        ...queryObject,
        page: '',
        // Null values will be stripped, RESULT page=1
        query,
      })}`
      updateAppUrl({
        app: BROWSE,
        href,
      })
    },
  },
  {
    page: CURATION,
    delayed: true,
    action: (query: string): void => {
      const { pathname, search } = window.location
      // We NEED to break down search and merge/overwrite QUERY
      const queryObject = stringToQueryObject(search)
      const href = `${pathname}?${objectToQueryString({
        ...queryObject,
        page: '',
        // Null values will be stripped, RESULT page=1
        query,
      })}`
      updateAppUrl({
        app: CURATION,
        href,
      })
    },
  },
  {
    page: VISII_SEARCH,
    delayed: true,
    action: (query: string): void => {
      defaultSearch(query)
    },
  },
]

const getCurrentPagePath = () => {
  const { pathname } = window.location
  const { url: currentPagePath } = window.history.state || {}

  // Special case for Visii Search to avoid empty pathname
  if (!currentPagePath && !!pathname.match(/^(\/en-[A-Za-z]{2})?\/vsearch/)) {
    return VISII_SEARCH
  }

  // BlueCore from GTM is overridden the window.history.state
  return currentPagePath || BROWSE
}

const checkForCustomSearch = (): string => {
  const currentPagePath = getCurrentPagePath()
  // Grab all PAGES
  const pagesAvailable: Array<string> = CUSTOM_SEARCH.map(({ page }) => page)

  // Check if any match
  const currentPage = pagesAvailable.find((page) => currentPagePath.search(page) > -1)
  return currentPage || 'unknownPage'
}

const getCustomSearchAction = (currentPage: string) => {
  const searchAction = CUSTOM_SEARCH.find(({ page }) => page === currentPage)
  return searchAction ? searchAction.action : null
}

// Custom Timer Logic for Search Input Field Updates
let timer
const clearTimer = () => {
  clearTimeout(timer)
  timer = null
}

export const submitSearch = (query: string): void => {
  // If USER clicks enter, clear out any previous DELAYED searches
  clearTimer()
  // Make sure we do NOT have custom logic for a NEXT page
  const currentPage = checkForCustomSearch()

  if (currentPage) {
    const searchAction = getCustomSearchAction(currentPage)
    if (searchAction) {
      searchAction(query)
      return
    }
  }

  defaultSearch(query)
}

export const transformNavLinks = ({
  navigationLinks,
  transformFunction,
}: {
  navigationLinks: NavigationLinksArray
  transformFunction: (navigationLinks: NavigationLinksArray) => NavigationLinksArray
}): NavigationLinksArray => {
  return transformFunction(navigationLinks)
}

export const abTestInSubNavLinks = ({
  navigationLinks,
  abTest,
}: {
  navigationLinks: NavigationLinksArray
  abTest: Record<string, any>
}): NavigationLinksArray => {
  const replaceSubNavLinkUrl = (url: string) => {
    const AALanderABTest = abTest[38]
    const LEGACY = '01'

    switch (url) {
      case '/artadvisory/brief':
        return AALanderABTest === LEGACY ? url : '/artadvisory#contact-us'

      default:
        return url
    }
  }

  return navigationLinks.map(({ links, ...navigationLinksRest }) => {
    return {
      ...navigationLinksRest,
      links: links.map(({ links: subNavLinks, ...subNavigationLinksRest }) => {
        return {
          ...subNavigationLinksRest,
          links: subNavLinks.map(({ url, ...rest }) => ({
            ...rest,
            url: replaceSubNavLinkUrl(url),
          })),
        }
      }),
    }
  })
}
