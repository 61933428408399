import React, { FC } from 'react'
import UserPreferences, { ConnectorProps as UserPreferencesProps } from 'connectors/UserPreferences'
import styles from './Preferences.module.scss'
import { MODAL_NAME } from 'components/Preferences/Modal/Preferences'
import ArrowRightIcon from 'svg/thicker-arrow.svg'
import CountryFlagSvg from 'components/CountryFlag/CountryFlagSvg'
import useModal from 'hooks/useModal'

type PreferencesProps = UserPreferencesProps

const PreferencesFooter: FC<PreferencesProps> = (props) => {
  const {
    localeIsReady,
    userPreferences,
    userPreferencesCountryCode,
    userPreferencesCountryName,
    userPreferencesCurrency,
    userPreferencesMeasurementUnit,
  } = props
  const { showModal } = useModal()

  // Hide currency selector for specific pages
  if (!localeIsReady || !userPreferences) {
    return null
  }

  return (
    <div
      title='View Store Preferences Footer'
      className={styles.userPreferencesBox}
      onClick={() => showModal(MODAL_NAME)}
      data-type='country-flag-icon-footer'
    >
      <CountryFlagSvg
        customClassName={styles.userPreferencesCountryFlag}
        country={userPreferencesCountryCode?.toLowerCase()}
      />
      <div className={styles.userPreferencesText}>
        <span>{userPreferencesCountryName}</span>
        {'/'}
        <span>{userPreferencesCurrency}</span>
        {'/'}
        <span className={styles.userPreferencesUnit}>{userPreferencesMeasurementUnit}</span>
        <ArrowRightIcon />
      </div>
    </div>
  )
}

export default UserPreferences(PreferencesFooter)
