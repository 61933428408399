import React from 'react'
import styles from './MainTopHeader.module.scss'
import accountStyles from '../../IconActions/User/Account.module.scss'
import Back from 'svg/caret-left.svg'
import UserIcon from 'svg/user.svg'
import SessionConnector, { ConnectorProps as SessionProps } from 'containers/Session'
import classNames from 'classnames'
import { capitalizeString } from 'lib/helpers'
import { useHeaderContext } from '../../HeaderContext'

const MainTopHeader = ({ fullName, userInitials, authenticated }: SessionProps) => {
  const { setSelectedCategory, setMobileHeaderConfig } = useHeaderContext()

  const backToCategoriesMenu = () => {
    setMobileHeaderConfig((previousMobileHeaderConfig) => ({
      ...previousMobileHeaderConfig,
      mobileMenuContent: 'categories',
      mobileTopHeader: 'mainTopHeader',
    }))
    setSelectedCategory(null)
  }

  const fullNameTruncated = fullName?.length > 35 ? fullName?.slice(0, 35).concat('...') : fullName
  const capitalizedFullName = capitalizeString(fullNameTruncated)

  return (
    <>
      <div className={styles.topHeaderMenuAccount}>
        <div onClick={backToCategoriesMenu}>
          <Back className={styles.closeIcon} />
        </div>
        <div className={styles.user}>
          {authenticated ? (
            <span className={accountStyles.accountIconInitials}>{userInitials}</span>
          ) : (
            <UserIcon className={styles.userIcon} />
          )}
          <p className={classNames('defaultTypographyH5', styles.userName)}>
            {capitalizedFullName ? capitalizedFullName : 'Log In / Register'}
          </p>
        </div>
      </div>
    </>
  )
}

export default SessionConnector(MainTopHeader)
