import url from 'url'
import { updateAppUrl } from 'lib/url/route'
import { urlQuery, objectToQueryString } from 'lib/helpers'
import { DEFAULT_APP, DEFAULT_URL } from './constants'

// We need a way to pull SPECIAL Query Params for ALGOLIA
// Ex: hitsPerPage, page, sort
export const getPageParamsQuery = (href: string = ''): Record<string, any> => {
  const { query } = url.parse(href, true)
  const { hitsPerPage, page } = query || {}
  return {
    hitsPerPage,
    page,
  }
}
// Location URL
// queryParams merged attributes to create url  => ...selectedFacets, ...algoliaQuery, ...appQuery, ...params
export const generateLocationUrl = ({
  urlPathString,
  queryParams,
}: Record<string, any>): string => {
  const queryString = urlQuery(queryParams)
  return `${urlPathString}${queryString}`
}

export const createLocationUrl = ({ section, subSection, page, hitsPerPage }: Params): string => {
  const queryString = urlQuery({
    page,
    hitsPerPage,
  })
  return `${DEFAULT_URL}${section ? `/${section}` : ''}${
    subSection ? `/${subSection}` : ''
  }${queryString}`
}

type Params = {
  section: string
  subSection?: string
  artworkId?: string
  page?: number
  hitsPerPage?: number
}

export const updateStudioSalesUrl = (
  //href: string,
  //section: string,
  //subSection?: string,
  //artworkId?: string
  params: Params
): void => {
  //const { query } = parse(href) || {}
  const app = `${DEFAULT_APP}?${objectToQueryString(params)}`
  const href = createLocationUrl(params)
  updateAppUrl({
    app,
    href,
  })
}
