import React, { FC } from 'react'
import UserPreferences, { ConnectorProps as UserPreferencesProps } from 'connectors/UserPreferences'
import styles from './CountryFlagIcon.module.scss'
import { MODAL_NAME } from 'components/Preferences/Modal/Preferences'
import CountryFlagSvg from 'components/CountryFlag/CountryFlagSvg'
import classNames from 'classnames'
import useModal from 'hooks/useModal'

type CountryFlagIconProps = UserPreferencesProps & { customClassName?: string }

const CountryFlagIcon: FC<CountryFlagIconProps> = ({
  customClassName = '',
  userPreferencesCountryCode,
}) => {
  const { showModal } = useModal()
  return (
    <div
      title='View Store Preferences'
      className={classNames(styles.countryFlagBox, {
        [customClassName]: customClassName !== '',
      })}
      onClick={() => showModal(MODAL_NAME)}
      data-type='country-flag-icon'
    >
      <CountryFlagSvg
        country={userPreferencesCountryCode?.toLowerCase()}
        customClassName={customClassName}
      />
    </div>
  )
}

export default UserPreferences(CountryFlagIcon)
