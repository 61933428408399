import React, { FC } from 'react'
import UserPreferences, { ConnectorProps as UserPreferencesProps } from 'connectors/UserPreferences'
import styles from './DesktopHeader.module.scss'
import SearchInput from '../Search/SearchInput'
import IconActions from '../IconActions/IconActions'
import CategoriesCTAWrapper from './CategoriesCTA/CategoriesCTAWrapper'
import SALogo from '../IconActions/SALogo'
import classNames from 'classnames'
import { submitSearch } from '../helpers'

const DesktopHeader: FC<UserPreferencesProps> = ({ isAuthenticated }) => {
  return (
    <div className={styles.headerContainer} data-type='desktop-header'>
      <div className={styles.headerLogoActionsContainer}>
        <SALogo />

        <div className={styles.headerSearchActionsContainer}>
          <div className={styles.headerSearchBox}>
            <SearchInput onSearch={submitSearch} />
          </div>

          <div
            className={classNames(styles.headerActionsBox, {
              [styles.headerActionsBoxLogged]: isAuthenticated,
            })}
          >
            <IconActions modalName='user-preferences-header-desktop' />
          </div>
        </div>
      </div>

      <CategoriesCTAWrapper />
    </div>
  )
}

export default UserPreferences(DesktopHeader)
