import url from 'url'
import { updateAppUrl } from 'lib/url/route'
import parse from 'lib/url/parse'
import { urlQuery, objectToQueryString } from 'lib/helpers'
import { DEFAULT_APP, DEFAULT_URL, SECTIONS } from './constants'
const { DASHBOARD, ARTWORK_OVERVIEW } = SECTIONS
// We need a way to pull SPECIAL Query Params for ALGOLIA
// Ex: hitsPerPage, page, sort
export const getPageParamsQuery = (href: string = ''): Record<string, any> => {
  const { query } = url.parse(href, true)
  const { hitsPerPage, page } = query || {}
  return {
    hitsPerPage,
    page,
  }
}
// Location URL
// queryParams merged attributes to create url  => ...selectedFacets, ...algoliaQuery, ...appQuery, ...params
export const generateLocationUrl = ({
  urlPathString,
  queryParams,
}: Record<string, any>): string => {
  const queryString = urlQuery(queryParams)
  return `${urlPathString}${queryString}`
}
// TODO: define enum type for all section values that can be called here
export const updateStudioUrl = (href: string, section: string, artworkId?: string): void => {
  const { query } = parse(href) || {}
  const app = `${DEFAULT_APP}?${objectToQueryString({
    section,
    artworkId,
    ...query,
  })}`
  updateAppUrl({
    app,
    href,
  })
}
export const getDashboardUrl = (params?: Record<string, any>): string =>
  `${DEFAULT_URL}?${objectToQueryString(params)}`

export const updateDashboardUrl = (params?: Record<string, any>): void => {
  const section = DASHBOARD
  const app = `${DEFAULT_APP}?${objectToQueryString({
    section,
    ...params,
  })}`
  const href = `${DEFAULT_URL}?${objectToQueryString(params)}`
  updateAppUrl({
    app,
    href,
  })
}
export const updateArtworkOverviewUrl = (artworkId: string): void => {
  const section = ARTWORK_OVERVIEW
  const app = `${DEFAULT_APP}?${objectToQueryString({
    section,
    artworkId,
  })}`
  const href = `${DEFAULT_URL}/art/${artworkId}/${section}`
  updateAppUrl({
    app,
    href,
  })
}
