import easelAPI, { postOptions, EaselResponse } from 'apis/helpers/easelAPI'

export async function newsletterSignupApi(params: Record<string, any>): Promise<EaselResponse> {
  const options = { ...postOptions, body: JSON.stringify({ ...params }) }
  const json = await easelAPI.fetchJSON({
    name: 'newsletterSignupApi',
    options,
    url: `/newsletter/signup`,
  })
  return json
}
