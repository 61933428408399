import React, { FC, useEffect, useState } from 'react'
import debounce from 'lib/debounce'
import FlashMessagesConnector, {
  ConnectorProps as FlashMessageProps,
} from 'containers/FlashMessage'
import Portal from './Portal'
import './helpers'
import SAAlert from 'components/SA/Alert'

const messageDebounce = debounce()
const cleanupDebounce = debounce()
const MESSAGE_DURATION = 3500
const CLEANUP_DURATION = 500

const FlashMessage: FC<FlashMessageProps> = (props) => {
  const [isVisible, handleIsVisible] = useState(true)
  const { clearMessage, available, type, messages } = props

  const closeFlash = (ev?: any) => {
    if (ev) {
      ev.preventDefault()
    }

    // How long after CLOSE to wipe out ALL messages
    cleanupDebounce(() => {
      clearMessage()
      handleIsVisible(true)
    }, CLEANUP_DURATION)
    handleIsVisible(false)
  }

  useEffect(() => {
    messageDebounce(() => {
      closeFlash()
    }, MESSAGE_DURATION)
  }, [messages])
  return (
    <>
      {available && (
        <Portal {...props} closeFlash={closeFlash} isVisible={isVisible} type={type}>
          {messages.map((message, key) => (
            <SAAlert
              key={key}
              type={type}
              text={message}
              onClose={closeFlash}
              hasBorderRadius={false}
            />
          ))}
        </Portal>
      )}
    </>
  )
}

export default FlashMessagesConnector(FlashMessage)
