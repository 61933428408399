import { createSelector } from 'reselect'
import { getPageData } from 'selectors/page'
import { getMeasurementUnit } from 'selectors/user/session'
import { getArtworkId } from './app'
type State = {
  artwork: Record<string, any>
}
export const getArtwork = (state: State) => state.artwork || {}
export const getArtworkMetaData = createSelector(
  [getPageData],
  (pageData): Record<string, any> => pageData.artworkMetadata || {}
)
export const getCategories = createSelector(
  [getArtworkMetaData],
  (artworkMetaData): Array<Record<string, any>> => artworkMetaData.categories || []
)
export const getProducts = createSelector(
  [getArtwork],
  (artwork): Array<Record<string, any>> => artwork.products || []
)
// all selectors for original product
export const getOriginalProducts = createSelector(
  [getProducts],
  (products: Array<Record<string, any>>): Array<Record<string, any>> =>
    products.filter((item) => item.isOriginal) || []
)
export const getOriginalProduct = createSelector(
  [getOriginalProducts],
  (products): Record<string, any> => products[0] || {}
)
export const getOriginalProductIsReserved = createSelector(
  [getOriginalProducts],
  (product): boolean => product.isReserved || false
)
export const getOriginalProductOriginalInfo = createSelector(
  [getOriginalProduct],
  (originalProduct): Record<string, any> => originalProduct.original || {}
)
export const getOriginalProductOriginalInfoPrices = createSelector(
  [getOriginalProductOriginalInfo],
  (originalInfo): Record<string, any> => {
    const { artistPrice, priceToPay } = originalInfo || {}
    return {
      artistPrice: artistPrice || '',
      customerPrice: priceToPay || '',
    }
  }
)
export const getOriginalProductSku = createSelector(
  [getOriginalProductOriginalInfo],
  (originalInfo): Record<string, any> => originalInfo.sku || ''
)
export const getOriginalProductData = createSelector(
  [getOriginalProducts, getMeasurementUnit],
  (product, measurementUnit): Record<string, any> => {
    const originalProduct = product[0] || {}

    // Existing Product
    if (originalProduct.original) {
      const {
        address: { addressBookItemId },
        dimensions: { weight },
        hasOriginalFrame: isFramed,
        isAPLE,
        dimensions,
        isReadyToHang,
        originalFrameColor: frameColor,
        packagingOption: packaging,
        panels,
        aisp: { freightAmount: aisp },
        artistPrice: price,
        status: { currentStatus: availability },
        stock: { unitsLeft, unitsProduced, unitsSold, unitsSoldOutside },
      } = originalProduct.original
      return {
        addressBookItemId,
        availability,
        isReserved: originalProduct.isReserved,
        currentProductStatus: availability,
        frameColor: frameColor || '',
        // TODO: fix nested ternary

        isFramed: isFramed !== null ? (isFramed ? '1' : '0') : 'na',
        isLimitedEdition: isAPLE ? '1' : '0',
        isMultiPaneled: panels > 1 ? '1' : '0',
        // TODO: fix nested ternary

        isReadyToHang: isReadyToHang !== null ? (isReadyToHang ? '1' : '0') : 'na',
        originalUnitsSoldOutside: unitsSoldOutside,
        packaging,
        panels,
        price,
        aisp,
        productDimensions: dimensions,
        unitsLeft,
        unitsProduced,
        unitsSold,
        unitsSoldOutside,
        unitsAvailable: Number(unitsProduced) - Number(unitsSoldOutside) - Number(unitsSold),
        weight,
      }
    }

    // New Product
    return {}
  }
)
export const getArtistInfo = createSelector(
  [getPageData],
  (pageData): Record<string, any> => pageData.artistInfo || {}
)
export const getArtistAddresses = createSelector(
  [getArtistInfo],
  (artistInfo): string => artistInfo.addresses || []
)
export const getArtistID = createSelector(
  [getArtistInfo],
  (artistInfo): string => artistInfo.userId || 0
)
export const getArtistName = createSelector(
  [getArtistInfo],
  (artistInfo): string => `${artistInfo.firstName} ${artistInfo.lastName}` || ''
)
export const getArtistUserName = createSelector(
  [getArtistInfo],
  (artistInfo): string => artistInfo.userName || ''
)
export const getArtistIsVerifiedToSell = createSelector(
  [getArtistInfo],
  (artistInfo): boolean => artistInfo.isVerifiedToSell || false
)
export const getArtistIsOnVacation = createSelector(
  [getArtistInfo],
  (artistInfo): boolean => artistInfo.isOnVacation || false
)
export const getArtistIsEmailVerified = createSelector(
  [getArtistInfo],
  (artistInfo): boolean => artistInfo.isEmailVerified || false
)
export const getArtistIsAllowedToSell = createSelector(
  [getPageData],
  (pageParams): boolean => pageParams.artistInfo?.isAllowedToSell || false
)
export const getReasonsNotAllowedToSell = createSelector(
  [getPageData],
  (pageParams): Array<string> => pageParams.artistInfo?.reasonsNotAllowedToSell || []
)
export const getArtistEmail = createSelector(
  [getArtistInfo],
  (artistInfo): boolean => artistInfo.email || false
)
// all selectors for open edition print
export const getPrintProducts = createSelector(
  [getProducts],
  (products: Array<Record<string, any>>): Array<Record<string, any>> =>
    products.filter((item) => !item.isOriginal) || []
)
export const getHasPrintProducts = createSelector(
  [getPrintProducts],
  (products): boolean => products.length > 0
)
export const getOpenEditionProducts = createSelector(
  [getProducts],
  (products: Array<Record<string, any>>): Array<Record<string, any>> =>
    products.filter((item) => item.isOpenEdition) || []
)
export const getLimitedEditionProducts = createSelector(
  [getProducts],
  (products: Array<Record<string, any>>): Array<Record<string, any>> =>
    products.filter((item) => item.isLimitedEdition) || []
)
export const getHasOpenEditionPrintProducts = createSelector(
  [getOpenEditionProducts],
  (products): boolean => products.length > 0
)
export const getHasLimitedPrintProducts = createSelector(
  [getLimitedEditionProducts],
  (products): boolean => products.length > 0
)
export const getArtworkImageData = createSelector(
  [getArtwork],
  (artwork): Record<string, any> => artwork.artworkImage || {}
)
export const getArtworkImageUrl = createSelector(
  [getArtworkImageData],
  (artworkImageData): string => artworkImageData.imageUrl || ''
)
export const getArtworkImageCrops = createSelector(
  [getArtworkImageData],
  (artworkImageData): Record<string, any> => artworkImageData.crops || {}
)
export const getArtworkImageStudioCrops = createSelector(
  [getArtworkImageCrops],
  (crops): Record<string, any> => crops.studio || {}
)
export const getArtworkImageStudioPrintCrops = createSelector(
  [getArtworkImageStudioCrops],
  (studioCrops): Record<string, any> => studioCrops.print || {}
)
// Find all Materials => ['Fine Art Paper', 'Canvas']
export const getExistingPrintProductMaterials = createSelector(
  [getPrintProducts],
  (products: Array<Record<string, any>>): Array<string> =>
    products.reduce((results, { material }) => {
      if (!results.includes(material)) {
        results.push(material)
      }

      return results
    }, [])
)
// limited Edition prints
// Get First Item and pull DATA needed to recreate form
export const getExistingLimitedPrintProduct = createSelector(
  [getPrintProducts],
  (printProducts): Record<string, any> => {
    return printProducts[0] || {}
  }
)
export const getExistingLimitedPrintMaterial = createSelector(
  [getExistingLimitedPrintProduct],
  (product): string => product.material || ''
)
export const getUserMeasurementUnit = createSelector(
  [getArtistInfo],
  (artistInfo): string => artistInfo.measurementUnit || ''
)
export const getUserIsVerifiedToSell = createSelector(
  [getArtistInfo],
  (artistInfo): string => artistInfo.isVerifiedToSell || ''
)
export const getArtworkIsDeleted = createSelector(
  [getArtwork],
  (artwork): boolean => artwork.isDeleted || false
)
export const getIsArtworkDetailsValid = createSelector(
  [getArtwork],
  (artwork): boolean => artwork.canBePublished || artwork.visibility === 'published' || false
)
export const getArtistIsBanned = createSelector(
  [getReasonsNotAllowedToSell],
  (reasonsNotAllowedToSell): boolean => reasonsNotAllowedToSell.includes('BANNED')
)
export const getArtworkFormData = createSelector(
  [getArtwork, getUserMeasurementUnit],
  (artwork, measurementUnit): Record<string, any> => {
    const {
      artworkId,
      artworkImage,
      category,
      dimensions,
      description,
      keywords,
      materials,
      mediums,
      styles,
      subject,
      title,
      yearProduced,
      hasOpenEditions,
      visibility,
      canBePublished,
      additionalImages,
    } = artwork
    const { imageUrl, crops } = artworkImage || {}
    const { studio: studioCrops } = crops || {}
    const { scale, square } = studioCrops || {}
    // We need to convert DIMENSIONS from CM => IN based on preferences ( stored in CM )
    // Object.keys(dimensions).forEach(
    //   (attribute) =>
    //     (dimensions[attribute] = convertSizeForClient(dimensions[attribute], measurementUnit))
    // )

    return {
      artworkImage: {
        image: imageUrl,
      },
      artworkId,
      category,
      crops: {
        scale,
        square,
      },
      description,
      keywords,
      materials,
      mediums,
      styles,
      subject,
      title,
      yearProduced,
      hasOpenEditions,
      visibility,
      canBePublished,
      additionalImages,
      ...dimensions,
      additionalImagesChanged: false,
    }
  }
)
export const getArtworkImageFormData = createSelector(
  [getArtwork],
  (artwork): Record<string, any> => {
    const { artworkId, artworkImage, title, additionalImages } = artwork
    const { imageUrl, crops } = artworkImage || {}
    const { studio: studioCrops } = crops || {}
    const { scale, square } = studioCrops || {}
    return {
      artworkImage: {
        image: imageUrl,
      },
      artworkId,
      crops: {
        scale,
        square,
      },
      title,
      additionalImages,
      additionalImagesChanged: false,
    }
  }
)
export const getArtworkDescriptionFormData = createSelector(
  [getArtwork, getUserMeasurementUnit],
  (artwork, measurementUnit): Record<string, any> => {
    const {
      artworkId,
      category,
      dimensions,
      description,
      keywords,
      materials,
      mediums,
      styles,
      subject,
      yearProduced,
      hasOpenEditions,
      visibility,
    } = artwork
    return {
      artworkId,
      category,
      description,
      keywords,
      materials,
      mediums,
      styles,
      subject,
      yearProduced,
      hasOpenEditions,
      visibility,
      ...dimensions,
    }
  }
)
export const getDataLayer = createSelector(
  [getArtistID, getArtistName, getArtworkId],
  (artistID, artistName, artworkID): Record<string, any> => ({
    'artist id': artistID,
    'artist name': artistName,
    'artwork id': artworkID,
    pagetype: 'studio',
    'subpage type': 'upload form',
    'subsubpage type': '',
  })
)
