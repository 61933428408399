import React, { FC, useEffect, useState } from 'react'
import SALink from 'components/SALink'
import { handleScreenReader } from 'lib/screenReader'
import ExclamationCircleIcon from 'svg/exclamation-circle.svg'
import {
  checkExpiredLogin,
  resetNotification,
  sendCartUrgencyDataLayerEvent,
  showNotification,
} from './helper'
import {
  CartUrgencyWrapper,
  CloseIcon,
  CartUrgencyTextContainer,
  CartUrgencyCloseIconContainer,
} from './styles'
import classNames from 'classnames'
import styles from './CartUrgencyBar.module.scss'
import ClientConnector, { ConnectorProps as ClientConnectorProps } from 'connectors/Client'

const CartUrgencyBar: FC<ClientConnectorProps> = ({ isClientReady }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [triggerCloseAnimation, setTriggerCloseAnimation] = useState(false)

  useEffect(() => {
    if (isClientReady) {
      checkExpiredLogin()
      const canShow = showNotification()
      setIsVisible(canShow)
      sendCartUrgencyDataLayerEvent()
    }
  }, [isClientReady])

  const handleCloseClick = () => {
    resetNotification()
    setIsVisible(false)
    setTriggerCloseAnimation(true)
  }

  const shouldCloseAnimation = triggerCloseAnimation ? 'fadeOut' : ''
  const animation = isVisible ? 'fadeIn' : shouldCloseAnimation

  if (!isVisible) {
    return null
  }

  return (
    <CartUrgencyWrapper data-animation={animation}>
      <CartUrgencyTextContainer>
        <ExclamationCircleIcon />
        <p
          className={classNames('defaultTypographyCtaMedium', styles.cartUrgencyText)}
          data-style='paragraph-wp'
        >
          Artworks in your cart are not reserved
        </p>
        <p
          className={classNames('defaultTypographyCtaMedium', styles.cartUrgencyText)}
          data-style='paragraph-wp'
        >
          <SALink
            href='/checkout?ucb-checkout-now'
            data-style='cart-link'
            onClick={resetNotification}
          >
            Checkout Now
          </SALink>
        </p>
      </CartUrgencyTextContainer>
      <CartUrgencyCloseIconContainer>
        <div
          data-style='icon-clickable-div'
          role='button'
          tabIndex={0}
          onClick={handleCloseClick}
          onKeyDown={(ev) => {
            handleScreenReader(ev) && handleCloseClick()
          }}
        >
          <CloseIcon />
        </div>
      </CartUrgencyCloseIconContainer>
    </CartUrgencyWrapper>
  )
}

export default ClientConnector(CartUrgencyBar)
