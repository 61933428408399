import React from 'react'
import styles from './MainTopHeader.module.scss'
import SALogo from '../../IconActions/SALogo'
import IconActions from '../../IconActions/IconActions'

const MainTopHeader = () => {
  return (
    <>
      <div className={styles.topHeaderMenuIconBox}>
        <SALogo />
      </div>
      <div className={styles.iconsWrapper}>
        <IconActions modalName='user-preferences-header-mobile' />
      </div>
    </>
  )
}

export default MainTopHeader
