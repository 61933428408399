import React, { useRef } from 'react'
import styles from './CategoriesCTA.module.scss'
import classNames from 'classnames'
import SALink from 'components/SALink'
import { useHeaderContext } from '../../HeaderContext'

const CategoriesCTA = () => {
  const { selectedCategory, setSelectedCategory, navigationLinks } = useHeaderContext()
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const handleMouseOver = (category: string) => {
    const timeoutInMiliseconds = selectedCategory === null ? 500 : 0
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      setSelectedCategory(category)
    }, timeoutInMiliseconds)
  }

  const handleMouseOut = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }

  return (
    <ul className={styles.categoriesCtaContainer}>
      {navigationLinks.map(({ title, url }, index) => (
        <li
          className={classNames('defaultTypographyH5', styles.categoriesCtaText)}
          key={`${title}${index}`}
          onMouseOver={() => handleMouseOver(title)}
          onMouseOut={handleMouseOut}
        >
          <SALink className={classNames('defaultLink')} href={url} data-gtm-event='top-nav'>
            {title}
          </SALink>
        </li>
      ))}
    </ul>
  )
}

export default CategoriesCTA
