import React, { FC } from 'react'
import styled from 'styled-components'
import { display } from 'styled-system'
import SessionConnect, { ConnectorProps as SessionProps } from 'containers/Session'
import Undo from 'svg/undo.svg'

const UndoIcon = styled(Undo)`
  width: 12px;
  height: 12px;
  margin-right: 5px;
`
const Indicator = styled.div`
  padding: 0 ${(props) => props.theme.space[4]}px;
  text-align: right;
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSizes[1]}px;
  background-color: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
  a {
    color: ${(props) => props.theme.colors.default};
    display: inline-block;
  }
  div, i {
    margin-right: 5px;
  }

  > * {
      text-decoration: none;
      display: block;
      padding: 12px 0;
  }
  ${display}
}
`
const Myself = styled.span`
  color: ${(props) => props.theme.colors.blue};
`
type Props = SessionProps

const TakeoverIndicator: FC<Props> = ({ isTakeover }) => {
  if (!isTakeover) {
    return null
  }

  return (
    <Indicator>
      <a href='/accounts/untakeover'>
        <div>
          <UndoIcon />
          Return to being <Myself>myself</Myself>
        </div>
      </a>
    </Indicator>
  )
}

export default SessionConnect(TakeoverIndicator)
