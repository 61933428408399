import React from 'react' // eslint-disable-line

import styled from 'styled-components'
const BOTTOM_MENU_HEIGHT = 60
export const Header = styled.div`
  position: relative;
  z-index: 5;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    position: fixed;
    top: 0;
    height: ${BOTTOM_MENU_HEIGHT - 3}px;
    z-index: 110;
  }

  [data-section='logos'] {
    display: flex;

    a[data-type='logo'] {
      position: relative;
      padding: 10px 25px;
      border-right: 1px solid #eeeeee;
      svg {
        transition: opacity 0.2s ease-in-out 0s;
        will-change: opacity;
        opacity: 0.4;
      }
      &:hover svg {
        opacity: 1;
      }
      &[data-active] :after {
        position: absolute;
        content: '';
        background-color: rgb(51, 51, 51);
        height: 2px;
        bottom: -1px;
        left: 0px;
        right: 0px;
      }
    }

    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      display: none;
    }
  }

  a {
    text-decoration: none;
  }

  button {
    display: flex;
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 0;
  }

  [data-section='top'] {
    width: 100%;
    height: 42px;
    display: flex;
    padding: 0 15px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eeeeee;
    background: #ffffff;

    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      display: none;
    }

    [data-type='back'] {
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 7px;
        height: 14px;
        margin-right: 10px;
      }
      span {
        display: flex;
        font-size: 12px;
        line-height: 14px;
        align-items: center;
        letter-spacing: 0.16em;
        text-transform: uppercase;
        color: #333333;
      }
    }
  }

  [data-section='bottom'] {
    width: 100%;
    height: ${BOTTOM_MENU_HEIGHT}px;
    padding: 0 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #333333;
    border-bottom: 1px solid #eeeeee;

    a[data-type='logo'] {
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        margin: 5px 5px 0 0;
      }
      h2 {
        color: #ffffff;
      }
    }

    span[data-type='spacer'] {
      display: flex;
      width: 120px;
      @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
        display: flex;
        width: 100%;
        flex: 1 auto;
      }
    }

    #toggle-menu {
      display: none;

      &[data-checked] ~ span {
        opacity: 1;
        transform: rotate(45deg) translate(-2px, -1px);
        background: #ffffff;
        top: 23px;
        width: 50%;
        position: absolute;
        height: 1px;
        &:nth-last-child(2) {
          transform: rotate(-45deg) translate(0, -1px);
          top: 22px;
          position: absolute;
        }
        &:nth-last-child(3) {
          opacity: 0;
        }
      }
      &[data-checked] ~ [data-section='mobile-nav'] {
        transform: none;
      }
    }

    [data-type='hamburger'] {
      width: 55px;
      height: 100%;
      padding: 15px 12px;
      position: relative;
      [data-type='line'] {
        background-color: #ffffff;
        display: block;
        width: 100%;
        height: 2px;
        margin: 5px 0;
      }
      input {
        display: none;
      }
      @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
        display: none;
      }
    }

    [data-section='mobile-nav'] {
      position: fixed;
      top: ${BOTTOM_MENU_HEIGHT - 1}px;
      left: 0;
      z-index: 10;
      height: 100%;
      width: 100%;
      display: block;
      overflow: hidden scroll;
      background: #454545;
      -webkit-font-smoothing: antialiased;
      /* to stop flickering of text in safari */
      transform-origin: 0% 0%;
      transform: translate(-100%, 0);
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

      &[data-mobile-open='true'] {
        transform: none;
      }

      [data-type='mobile-nav-content'] {
        display: flex;
        flex-direction: column;
        padding: 15px 0;

        a {
          padding: 0 15px;
          width: 100%;
          color: #ffffff;
          + a {
            margin-top: 15px;
          }
        }
        [data-type='line'] {
          display: block;
          width: 100%;
          margin: 15px 0;
          border-bottom: 1px solid #dcdcdc;
        }
      }

      @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
        display: none;
      }
    }

    @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
      [data-type='userinfo'] {
        position: fixed;
        top: 0px;
        right: 15px;
      }
    }

    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      justify-content: flex-start;
      padding: 0 15px 0 0;
      margin-bottom: 50px;
    }
  }
`
export const NavigationIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1 auto;

  a {
    padding: 1px 6px;
  }

  button,
  a {
    display: flex;
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    min-width: 120px;
    height: ${BOTTOM_MENU_HEIGHT - 1}px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;

    &[data-active] {
      background: #666666;
    }

    svg {
      width: 25px;
      height: 20px;
      path,
      circle {
        stroke: #ffffff;
      }
      transition: 0.2s ease color, 0.2s ease-in-out transform;
      will-change: color, tranform;
    }
    span {
      font-size: 12px;
      line-height: 16px;
    }

    + button,
    + a {
      margin-left: 20px;
    }
  }

  &[data-type='desktop'] {
    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      display: none;
    }
  }

  &[data-type='mobile'] {
    margin-top: ${BOTTOM_MENU_HEIGHT - 3}px;
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    background: #ffffff;

    button,
    a {
      color: #333333;
      border-left: 1px solid #dcdcdc;

      &[data-active] {
        background: #f3f3f3;
        border-bottom: 5px solid #333333;
      }

      svg {
        path,
        circle {
          stroke: #333333;
        }
      }

      + button,
      + a {
        border-right: 1px solid #dcdcdc;
        margin-left: 0;
      }
    }
    @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
      display: none;
    }
  }
`
